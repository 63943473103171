<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>账户管理</p>
			<i>/</i>
			<p @click="jumpPage">管理员列表</p>
			<i>/</i>
			<p class="active">密码修改</p>
		</nav>
		
		<div class="page_edit">
			
			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="mobile">
						<p class="form_label_p">手机号</p>
						<el-input disabled class="input_long" v-model="ruleForm.mobile" maxlength="11" placeholder="请输入手机号">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="old_pwd">
						<p class="form_label_p">旧密码</p>
						<el-input class="input_long" show-password v-model="ruleForm.old_pwd" minlength="6" maxlength="18"
							placeholder="请输入旧密码">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="new_pwd">
						<p class="form_label_p">新密码</p>
						<el-input class="input_long" show-password v-model="ruleForm.new_pwd" minlength="6" maxlength="18"
							placeholder="请输入新密码">
						</el-input>
					</el-form-item>
					<div class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" @click="editHoldForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>
			
		</div>

	</div>
</template>

<script>
	export default {
		name: 'adminEditPassword',
		data() {
			return {
				ruleForm: {
					mobile: '', // 手机号
					old_pwd: '', // 旧密码
					new_pwd: '', // 新密码
				},
				rules: {
					mobile: [{
							required: true,
							message: '手机号不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号错误'
						}
					],
					old_pwd: [{
							required: true,
							message: '旧密码不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^\d{5,17}$/,
							message: '密码格式为6-18位数字'
						}
					],
					new_pwd: [{
							required: true,
							message: '新密码不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^\d{5,17}$/,
							message: '密码格式为6-18位数字'
						}
					],
				},
				modifyBtn: true, // 默认创建
			}
		},
		created() {
			const userInfrom = JSON.parse(window.localStorage.getItem("userInfrom"));
			if (userInfrom) {
				this.ruleForm.mobile = userInfrom.mobile
			}
		},
		methods: {
			// 密码编辑保存
			editHoldForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						if (this.ruleForm.new_pwd == this.ruleForm.old_pwd) {
							this.$alert('请设置与旧密码不一致的密码', '密码重复', {
								confirmButtonText: '确定',
								callback: () => {}
							});
							return
						}

						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}admin/editpwd`,
							data: {
								mobile: this.ruleForm.mobile,
								old_pwd: this.ruleForm.old_pwd,
								new_pwd: this.ruleForm.new_pwd,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$confirm('密码编辑成功, 重启后生效, 是否立即重启', '密码修改', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									// 退出
									this.signOutFn();
								}).catch(() => {
									this.$message({
										type: 'success',
										message: '稍后重启'
									});
								});
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
			// 退出
			signOutFn() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}adminauth/logout`,
					data: {}
				}).then(res => {
					if (res.code == 0) {
						localStorage.removeItem('token');
				
						this.$message({
							message: '退出成功',
							type: 'success'
						});
				
						this.$router.push('/login');
					} else {
						this.$message.error(`${res.msg}`);
					}
				})
			},
			
			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},
			
			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},
			
		}
	}
</script>

<style scoped lang="less">
	.input_long {
		width: 400px;
	}
	
</style>
